import { trackFrontendApisClientSideErrorError } from "@faire/web-api/events/frontendApis/error/clientSideError";
import { getGlobalProperty } from "@faire/web/common/globals/getGlobalProperty";
import { logFatal } from "@faire/web/common/logging";
import { truncate } from "@faire/web/common/truncate";
import { usePathname } from "next/navigation";
import { useEffect } from "react";

export const useLogFatalError = (error: Error) => {
  const pathname = usePathname();
  useEffect(() => {
    logFatal(error, {
      tags: {
        nextjs: 1,
      },
    });
    // since DD RUM samples errors, we need to track errors using PETE as well
    trackFrontendApisClientSideErrorError(
      // truncatePath was used to ensure error message is not too long
      // because PETE has a limit of characters
      truncate(
        `${error.message ?? "Unknown error"}\nStack: ${
          error.stack ?? "No stack available"
        }`,
        255
      ),
      // NEXT_JS is the source of the error, it is used to filter errors in PETE
      "NEXT_JS",
      pathname,
      getGlobalProperty("processingServer")
    );
  }, [error, pathname]);
};
