"use client";

import { FaireErrorBoundaryErrorContent } from "@faire/web/ui/FaireErrorBoundary/FaireErrorBoundary";
import React from "react";

import { useLogFatalError } from "../_lib/useLogFatalError";

const Error: React.FC<{ error: Error }> = ({ error }) => {
  useLogFatalError(error);
  return <FaireErrorBoundaryErrorContent fullPage error={error} />;
};

export default Error;
