"use client";

// Error components must be Client Components
import React from "react";

import Error from "./(app)/error";

const GlobalError = ({ error }: { error: Error }) => {
  return (
    <html>
      <body>
        <Error error={error} />
      </body>
    </html>
  );
};

export default GlobalError;
